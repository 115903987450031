

.slope {
  background: #cadc88;
  width: 100%;
  height: 20px;
  border-radius: 50% 50% 0px 0px / 100% 100% 0px 0px;
  overflow: hidden;
  z-index: 1;
}


.slope:before {
  content: '';
  background: #b29db4;
  border-radius: 50%;
  width: 80px;
  height: 10px;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: -5px;   
}

/* Button Styles */
button {
  padding: 8px 16px; 
  border-radius: 8px; 
  margin: 4px; 
  border: 0.5px solid black;
}

button.bg-blue-500 {
  background-color: #3b82f6; 
  color: white;
}

button.bg-gray-200 {
  background-color: #e5e7eb; 
  color: black;
}

/* Optional: Add hover effects */
button.bg-blue-500:hover {
  background-color: #2563eb; 
}

button.bg-gray-200:hover {
  background-color: #d1d5db; 
}


.background {
  width: 350px;
  height: 355px;
  position: relative;
  margin: auto;
  bottom: 0;
}

/*
.background {
  width: 350px;
  height: 445px;
  position: absolute;
  margin: auto;
  bottom: 0;
  left: -200px;
  right: -200px;
}
*/

.background .slope {
  position: absolute;
  bottom: 0;
}

.background>.flower {
  width: 25px;
  height: 25px;
  z-index: 0;
}

.background>.flower1 {
  top: 30px;
  left: 20%;
}

.background>.flower2 {
  top: 50px;
  left: 40%;
}

.background>.flower3 {
  top: 20px;
  left: 60%;
}

.background>.flower4 {
  top: 80px;
  left: 80%;
}

.background>.flower5 {
  top: 80px;
  left: 50%;
}

/* tree is the tree trunk */
.tree {
  width: 2px;
  /* height: -20px; */
  /* trunk width is 16px */
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  /* trunk color is brown */
  border-bottom: 300px solid #816954;
  position: absolute;
  left: 50%;

  /* this transform-origin seems to have no effect */
  transform-origin: 50% 300px;
  top: 50px;
  margin-left: -8px;
}

.tree>.flower {
  transform: rotate(25deg);
  width: 20px;
  height: 20px;
  top: -15px;
  left: -8px;
}

.tree>.flower2 {
  transform: rotate(20deg);
  width: 30px;
  height: 30px;
  top: -24px;
  left: -12px;
}


.branch {
  position: absolute;
  width: 150px;
  height: 40px;
  /* branch color is brown */
  border-bottom: 5px solid #816954;
}

.branch:before,
.branch:after {
  /* content: ''; */
  position: absolute;
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  z-index: 0;
}

.branch:before {
  transform: rotate(1deg);
}

.branch:after {
  transform: rotate(2deg);
}

.branch.left,
.branch.left:before,
.branch.left:after {
  transform-origin: 0px 0px;
}

.branch.right,
.branch.right:before,
.branch.right:after {
  transform-origin: 100% 0px;
}

.branch.left {
  border-bottom-left-radius: 100% 100%;
  transform-origin: 100% 100%;
}

.branch.right {
  border-bottom-right-radius: 100% 100%;
  transform-origin: 0% 100%;
  margin-left: 1px;
}

.tree>.branch.left {
  transform: rotate(5deg);
}

.tree>.branch.right {
  transform: rotate(-5deg);
}

.leaf {
  position: absolute;
  width: 23px;
  height: 23px;
  background: #c5eabe;
  border-radius: 0px 100%;
  z-index: 1;
  transform-origin: 0px 0px;
}


.leaf:before {
  /*    content:"";*/
  position: absolute;
  width: 2px;
  height: 2px;
  background: #f55;
}
.leaffordisplay {
  display: inline-block;
  position: relative;
  width: 23px;
  height: 23px;
  background: #c5eabe;
  border-radius: 0px 100%;
  margin-right: 8px;
  vertical-align: middle;
  transform-origin: 0px 0px;
}

.leaffordisplay:before {
  /*    content:"";*/
  position: absolute;
  width: 2px;
  height: 2px;
  background: #f55;
}

/* generic flowers are large. 50x50 */
.flower {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.flower:after {
  position: absolute;
  content: '';
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: #e55;
  border-radius: 50%;
  width: 30%;
  height: 30%;
  margin: auto;
}

.flower .petal {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(72deg);
}

.flower .petal::before,
.flower .petal::after {
  content: '';
  position: absolute;
  width: 60%;
  height: 60%;
  background: #f0a5a6;
  border-radius: 0px 100px;
  top: 50%;
  left: 50%;
  margin-left: -20%;
  margin-top: -20%;
  transform-origin: 33% 33%;
}

.flower .petal::after {
  transform: rotate(36deg);
}

.flower.petal5 .petal {
  transform: rotate(144deg);
}

.flower.petal5 .petal::after {
  transform: rotate(-72deg);
}

.flower.petal5>.petal::after {
  content: none;
}

.flower.petal6 .petal {
  transform: rotate(120deg);
}

.flower.petal6 .petal::after {
  transform: rotate(60deg);
}

.flower.petal8 .petal {
  transform: rotate(90deg);
}

.flower.petal8 .petal::after {
  transform: rotate(45deg);
  width: 56%;
  height: 56%;
}

.flower.roundpetal .petal:before,
.flower.roundpetal .petal:after {
  width: 30%;
  border-radius: 0px;
  margin-left: -15%;
  margin-top: 0%;
  top: 0%;
  height: 50%;
  border-radius: 50% 50% 0px 0px;
  transform-origin: 50% 100%;
}

.heart {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.heart:before,
.heart:after {
  position: absolute;
  content: '';
  width: 80%;
  height: 80%;
  border-radius: 100% 0%;
  background: #f0a5a6;
  left: 50%;
  top: 20%;
  transform-origin: 0% 100%;
}

.heart:before {
  transform: rotate(-65deg);
}

.heart:after {
  transform: rotate(-25deg);
}

.tulip {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 2;
  transform-origin: 50% 100%;
}

.tulip:before,
.tulip:after,
.tulip .peak {
  position: absolute;
  content: '';
  width: 80%;
  height: 80%;
  border-radius: 100% 0%;
  background: #f0a5a6;
  left: 50%;
  top: 20%;
  transform-origin: 0% 100%;
}

.tulip:before {
  transform: rotate(-70deg);
}

.tulip:after {
  transform: rotate(-20deg);
}

.tulip .peak {
  transform: rotate(-45deg);
}


.redflower:before,
.redflower:after,
.redflower .peak,
.redflower .petal:before,
.redflower .petal:after {
  background: #f07d7f;
}

.greenflower:before,
.greenflower:after,
.greenflower .peak,
.greenflower .petal:before,
.greenflower .petal:after {
  background: #aae76d;
}

.blueflower:before,
.blueflower:after,
.blueflower .peak,
.blueflower .petal:before,
.blueflower .petal:after {
  background: #79aaee;
}

.yellowflower:before,
.yellowflower:after,
.yellowflower .peak,
.yellowflower .petal:before,
.yellowflower .petal:after {
  /* background: #eed7a1; */
  background: #eed571;
}

.purpleflower:before,
.purpleflower:after,
.purpleflower .peak,
.purpleflower .petal:before,
.purpleflower .petal:after {
  background: #c26be4;
}

.blackflower:before,
.blackflower:after,
.blackflower .peak,
.blackflower .petal:before,
.blackflower .petal:after {
  background: #333;
}

.whiteflower:before,
.whiteflower:after,
.whiteflower .peak,
.whiteflower .petal:before,
.whiteflower .petal:after {
  background: #FFF;
}

/* this is the color of the center of the flower */
.flower:after {
  /* background: #eed5d5; */
  background: #eee5e5;
}

.branch .branch:after {
  content: none;
}

.tree>.leaf1 {
  top: 20px;
  left: 0px;
  width: 16px;
  height: 16px;
  transform: rotate(-180deg);
}

.tree>.leaf2 {
  top: 24px;
  left: 2px;
  width: 16px;
  height: 16px;
  transform: rotate(-30deg);
}

.tree>.leaf3 {
  top: 15px;
  left: 2px;
  width: 16px;
  height: 16px;
  transform: rotate(-85deg);
}

.branch1 {
  top: 190px;
  right: 0;
}


.branch1 .leaf1 {
  transform: rotate(-110deg);
  top: 78px;
  left: 115px;
}

.branch1 .leaf2 {
  transform: rotate(-135deg);
  top: 40px;
  left: 125px;
}

.branch1 .leaf3 {
  transform: rotate(-82deg);
  top: 36px;
  left: -12px;
}


.branch1 .branch-inner1 {
  width: 60px;
  height: 40px;
  transform: rotate(35deg);
  border-bottom-left-radius: 100% 70%;
  left: 50px;
  top: -2px;
  border-width: 5px;
}

.branch1 .branch-inner2 {
  width: 50px;
  height: 20px;
  transform: rotate(-40deg);
  border-bottom-left-radius: 100% 70%;
  left: 26px;
  top: 13px;
  border-width: 4px;
}

.branch1>.branch-inner2>.flower1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: 5px;
  bottom: 25px;
}

.branch1 .branch-inner3 {
  width: 20px;
  height: 10px;
  transform: rotate(40deg);
  border-bottom-left-radius: 100% 70%;
  left: 16px;
  top: 15px;
  border-width: 3px;
}

.branch1 .branch-inner1 .leaf1 {
  transform: rotate(-150deg);
  top: 38px;
  left: 40px;
}

.branch1 .branch-inner1 .leaf2 {
  width: 17px;
  height: 17px;
  transform: rotate(110deg);
  top: 38px;
  left: 20px;
}

.branch1 .branch-inner1 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(-150deg);
  top: 30px;
  left: 14px;
}

.branch1 .branch-inner2 .leaf1 {
  transform: rotate(80deg);
  left: 40px;
  top: 24px;
}

.branch1 .branch-inner2 .leaf2 {
  width: 20px;
  height: 20px;
  transform: rotate(-160deg);
  left: 30px;
  top: 20px;
}

.branch1 .branch-inner2 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(100deg);
  left: 10px;
  top: 18px;
}

.branch1 .branch-inner3 .leaf1 {
  width: 16px;
  height: 16px;
  transform: rotate(-150deg);
  left: 18px;
  top: 10px;
}

.branch1 .branch-inner3 .leaf2 {
  width: 16px;
  height: 16px;
  transform: rotate(130deg);
  left: 9px;
  top: 12px;
}

.branch1 .branch-inner3 .leaf3 {
  width: 16px;
  height: 16px;
  transform: rotate(130deg);
  left: 9px;
  top: 12px;
}

.branch1 .branch-inner3>.flower1 {
  transform: rotate(30deg);
  width: 15px;
  height: 15px;
  right: 15px;
  top: -5px;
}

.branch1 .branch-inner3 .flower2 {
  transform: rotate(45deg);
  width: 30px;
  height: 30px;
  right: 9px;
  top: -18px;
}

.branch1>.flower1 {
  width: 20px;
  height: 20px;
  right: 141px;
  top: -16px;
}

.branch1>.flower2 {
  width: 30px;
  height: 30px;
  right: 137px;
  top: -21px;
}


.branch1 .branch-inner1 .flower1 {
  width: 18px;
  height: 18px;

  top: 5px;
  left: -12px;
  transform: rotate(-25deg);
}

.branch1 .branch-inner1 .flower2 {
  transform: rotate(-25deg);
  width: 30px;
  height: 30px;
  top: 0px;
  left: -17px;
}

.branch1 .branch-inner1 .heart {
  width: 18px;
  height: 18px;
  bottom: 100%;
  right: 100%;
  transform: rotate(-35deg);
}

#branch1inner2flower {
  transform: rotate(15deg);
  width: 28px;
  height: 28px;
  right: 35px;
  bottom: 10px;
}

.branch1 .branch-inner2 .flower1 {
  transform: rotate(25deg);
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
}

.branch1 .branch-inner2 .flower2 {
  width: 30px;
  height: 30px;
  top: -14px;
  left: -15px;
  transform: rotate(-10deg);
}

.branch1 .branch-inner2 .heart {
  width: 18px;
  height: 18px;
  bottom: 100%;
  right: 100%;
  transform: rotate(-35deg);
}

.branch2 {
  height: 60px;
  top: 130px;
  left: 0;
}

.branch2 .heart {
  width: 18px;
  height: 18px;
  top: -8px;
  right: -12px;
  transform: rotate(35deg);
}

.branch2 .branch-inner1 {
  width: 60px;
  height: 70px;
  transform: rotate(90deg);
  border-bottom-left-radius: 100% 70%;
  left: -26px;
  top: -13px;
  border-width: 5px;
}

.branch2 .branch-inner2 {
  width: 60px;
  height: 80px;
  transform: rotate(60deg);
  border-bottom-right-radius: 100% 70%;
  left: 55px;
  top: -26px;
  border-width: 4px;
}

.branch2 .branch-inner3 {
  width: 40px;
  height: 30px;
  transform: rotate(30deg);
  border-bottom-right-radius: 100% 100%;
  left: 110px;
  top: 10px;
  border-width: 3px;
}

.branch2 .branch-subinner {
  width: 20px;
  height: 15px;
  transform: rotate(180deg);
  border-bottom-left-radius: 100% 100%;
  left: 0px;
  top: 8px;
  border-width: 2px;
}


.branch2 .leaf1 {
  transform: rotate(-150deg);
  top: 95px;
  left: 25px;
}

.branch2 .leaf2 {
  transform: rotate(-125deg);
  top: 47px;
  left: 85px;
}

.branch2 .leaf3 {
  transform: rotate(-145deg);
  top: 36px;
  left: 120px;
}


.branch2 .branch-inner1 .leaf1 {
  transform: rotate(120deg);
  top: 74px;
  left: 40px;
}

.branch2 .branch-inner1 .leaf2 {
  transform: rotate(220deg);
  top: 63px;
  left: 30px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner1 .leaf3 {
  transform: rotate(150deg);
  top: 60px;
  left: 14px;
  width: 17px;
  height: 17px;
}

.branch2 .branch-inner2 .leaf1 {
  transform: rotate(-20deg);
  top: 84px;
  left: 10px;
}

.branch2 .branch-inner2 .leaf2 {
  transform: rotate(-120deg);
  top: 73px;
  left: 25px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner2 .leaf3 {
  transform: rotate(-40deg);
  top: 65px;
  left: 44px;
  width: 17px;
  height: 17px;
}

.branch2 .branch-inner3>.leaf1 {
  transform: rotate(20deg);
  top: 32px;
  left: 10px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner3 .leaf2 {
  transform: rotate(-120deg);
  top: 25px;
  left: 20px;
  width: 20px;
  height: 20px;
}

.branch2 .branch-inner3 .leaf3 {
  transform: rotate(-50deg);
  top: 14px;
  left: 37px;
  width: 17px;
  height: 17px;
}

.branch2 .branch-subinner>.leaf1 {
  transform: rotate(150deg);
  top: 14px;
  left: 6px;
  width: 14px;
  height: 14px;
}

.branch2>.flower1 {
  width: 20px;
  height: 20px;
  right: -12px;
  top: -8px;
}

.branch2>.flower2 {
  transform: rotate(30deg);
  width: 30px;
  height: 30px;
  right: -12px;
  top: -8px;
}

.branch2 .heart {
  width: 18px;
  height: 18px;
  top: -8px;
  right: -12px;
  transform: rotate(35deg);
}

.branch2 .branch-inner1 .flower1 {
  width: 20px;
  height: 20px;
  left: -10px;
  top: 25px;
}

.branch2 .branch-inner1 .flower2 {
  transform: rotate(-10deg);
  width: 30px;
  height: 30px;
  left: -15px;
  top: 17px;
}

.branch2 .branch-inner1 .heart {
  width: 18px;
  height: 18px;
  bottom: -5px;
  left: -5px;
  transform: rotate(15deg);
}

.branch2 .branch-inner2 .heart {
  transform: rotate(10deg);
  width: 18px;
  height: 18px;
  left: 50px;
  top: 20px;
}

.branch2 .branch-inner2 .flower1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: -5px;
  bottom: 30px;
}

.branch2 .branch-inner2 .flower2 {
  transform: rotate(20deg);
  width: 30px;
  height: 30px;
  right: -12px;
  bottom: 30px;
}

.branch2 .branch-subinner .flower1 {
  width: 20px;
  height: 20px;
  left: -10px;
  top: -10px;
}

.branch2 .branch-inner3>.flower1 {
  transform: rotate(10deg);
  width: 15px;
  height: 15px;
  right: -5px;
  top: -5px;
}

.branch2 .branch-inner3 .flower2 {
  transform: rotate(25deg);
  width: 30px;
  height: 30px;
  right: -15px;
  bottom: 15px;
}

.branch2 .branch-subinner .flower2 {
  transform: rotate(10deg);
  width: 30px;
  height: 30px;
  right: 15px;
  bottom: -5px;
}

.branch3 {
  top: 70px;
  right: 0;
  width: 160px;
  height: 70px;
}


.branch3 .branch-inner1 {
  width: 60px;
  height: 40px;
  transform: rotate(-80deg);
  border-bottom-right-radius: 100% 100%;
  left: 70px;
  top: 15px;
  border-width: 4px;
}

.branch3 .branch-inner2 {
  width: 60px;
  height: 60px;
  transform: rotate(-40deg);
  border-bottom-left-radius: 100% 70%;
  left: 36px;
  top: 5px;
  border-width: 4px;
}

.branch3>.branch-inner2>.flower1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: 5px;
  bottom: 25px;
}

.branch3>.branch-inner1>.flower1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: 5px;
  bottom: 25px;
}

#branch3inner1flower {
  transform: rotate(30deg);
  width: 20px;
  height: 20px;
  right: -3px;
  bottom: 40px;
}

.branch3 .branch-inner1 .leaf1 {
  transform: rotate(-5deg);
  top: 44px;
  left: 10px;
}

.branch3 .branch-inner1 .leaf2 {
  width: 17px;
  height: 17px;
  transform: rotate(-95deg);
  top: 35px;
  left: 23px;
}

.branch3 .branch-inner1 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(-50deg);
  top: 27px;
  left: 48px;
}

.branch3 .branch-inner2 .leaf1 {
  transform: rotate(110deg);
  left: 50px;
  top: 64px;
  width: 17px;
  height: 17px;
}

.branch3 .branch-inner2 .leaf2 {
  width: 20px;
  height: 20px;
  transform: rotate(-160deg);
  left: 35px;
  top: 56px;
}

.branch3 .branch-inner2 .leaf3 {
  width: 17px;
  height: 17px;
  transform: rotate(125deg);
  left: 16px;
  top: 50px;
}

.branch3 .leaf1 {
  right: 10px;
  transform: rotate(200deg);
  bottom: -23px;
}

.branch3 .leaf2 {
  transform: rotate(230deg);
  right: 102px;
  bottom: 3px;
}

.branch3 .leaf3 {
  transform: rotate(120deg);
  right: 120px;
  bottom: 13px;
}

.branch3 .flower1 {
  width: 18px;
  height: 18px;
  left: -10px;
  top: -8px;
}

.branch3 .flower2 {
  width: 30px;
  height: 30px;
  left: -12px;
  top: -8px;
}

.branch3 .heart {
  width: 18px;
  height: 18px;
  top: -8px;
  left: -10px;
  transform: rotate(-10deg);
}

.branch3 .branch-inner1 .heart {
  width: 18px;
  height: 18px;

  top: 0px;
  left: 50px;
  transform: rotate(35deg);
}

.branch3 .branch-inner1 .flower1 {
  transform: rotate(10deg);
  width: 18px;
  height: 18px;
  left: 50px;
  top: -15px;
}

.branch3 .branch-inner1 .flower2 {
  transform: rotate(35deg);
  width: 30px;
  height: 30px;
  left: 50px;
  top: -15px;
}

.branch3 .branch-inner2 .heart {
  width: 18px;
  height: 18px;
  top: 0px;
  left: -10px;
  transform: rotate(35deg);
}

.branch3 .branch-inner2 .flower1 {
  transform: rotate(10deg);
  width: 18px;
  height: 18px;
  left: -10px;
  top: 0px;
}

.branch3 .branch-inner2 .flower2 {
  width: 30px;
  height: 30px;

  top: 0px;
  left: -10px;
  transform: rotate(35deg);
}

.branch3 .branch-inner2 .tulip {
  width: 20px;
  height: 20px;

  top: 20px;
  left: 0px;
  transform: rotate(-25deg);
}

.branch4 {
  width: 120px;
  height: 40px;
  top: 50px;
  left: 0;
}

.branch4 .leaf1 {
  transform: rotate(-150deg);
  top: 75px;
  left: 25px;
}

.branch4 .leaf2 {
  transform: rotate(-125deg);
  top: 27px;
  left: 85px;
}

.branch4 .leaf3 {
  transform: rotate(-197deg);
  top: 36px;
  left: 135px;
}

.branch4 .branch-inner1 {
  width: 50px;
  height: 60px;
  transform: rotate(90deg);
  border-bottom-left-radius: 100% 70%;
  left: -26px;
  top: -25px;
  border-width: 5px;
}

.branch4 .branch-inner2 {
  width: 50px;
  height: 70px;
  transform: rotate(60deg);
  border-bottom-right-radius: 100% 70%;
  left: 55px;
  top: -36px;
  border-width: 4px;
}

.branch4 .branch-inner1 .leaf1 {
  transform: rotate(120deg);
  top: 65px;
  left: 42px;
}

.branch4 .branch-inner1 .leaf2 {
  transform: rotate(220deg);
  top: 57px;
  left: 30px;
  width: 20px;
  height: 20px;
}

.branch4 .branch-inner1 .leaf3 {
  transform: rotate(150deg);
  top: 54px;
  left: 14px;
  width: 17px;
  height: 17px;
}

.branch4 .branch-inner2 .leaf1 {
  transform: rotate(35deg);
  top: 74px;
  left: 10px;
}

.branch4 .branch-inner2 .leaf2 {
  transform: rotate(-120deg);
  top: 65px;
  left: 20px;
  width: 20px;
  height: 20px;
}

.branch4 .branch-inner2 .leaf3 {
  transform: rotate(-40deg);
  top: 60px;
  left: 35px;
  width: 17px;
  height: 17px;
}

.branch4 .flower1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: -8px;
  bottom: 30px;
}

.branch4 .flower2 {
  transform: rotate(42deg);
  width: 30px;
  height: 30px;
  right: -12px;
  top: -8px;
}

.branch4 .heart {
  width: 18px;
  height: 18px;
  top: -8px;
  right: -12px;
  transform: rotate(35deg);
}

.branch4 .branch-inner1 .flower1 {
  width: 20px;
  height: 20px;
  left: -13px;
  top: 15px;
}

.branch4 .branch-inner1 .flower2 {
  transform: rotate(-8deg);
  width: 30px;
  height: 30px;
  left: -13px;
  top: 15px;
}

.branch4 .branch-inner1 .heart {
  width: 18px;
  height: 18px;
  top: 0px;
  left: -5px;
  transform: rotate(35deg);
}

.branch4 .branch-inner2 .heart {
  width: 18px;
  height: 18px;
  left: 40px;
  top: 10px;
  transform: rotate(-10deg);
}

.branch4 .branch-inner2 .flower1 {
  width: 18px;
  height: 18px;
  left: 40px;
  top: 10px;
}

.branch4 .branch-inner2 .flower2 {
  transform: rotate(30deg);
  width: 30px;
  height: 30px;
  left: 35px;
  top: 12px;
}


.branch4 .tulip1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: -8px;
  bottom: 30px;
}

.branch5 {
  top: 20px;
  right: 0;
  width: 90px;
  height: 30px;
}

.branch5 .leaf1 {
  width: 16px;
  height: 16px;
  transform: rotate(-135deg);
  top: 31px;
  left: 75px;
}

.branch5 .leaf2 {
  width: 16px;
  height: 16px;
  transform: rotate(-70deg);
  top: 30px;
  left: -5px;
}

.branch5 .leaf3 {
  width: 16px;
  height: 16px;
  transform: rotate(-150deg);
  top: 22px;
  left: 25px;
}


.branch5 .branch-inner1 {
  width: 30px;
  height: 30px;
  border-bottom-right-radius: 100% 100%;
  left: 50px;
  top: -5px;
  border-width: 4px;
  transform: rotate(-60deg);
}

.branch5 .branch-inner2 {
  width: 40px;
  height: 40px;
  transform: rotate(-50deg);
  border-bottom-left-radius: 100% 80%;
  left: 26px;
  top: -10px;
  border-width: 4px;
}

.branch5 .branch-inner1 .leaf1 {
  transform: rotate(-20deg);
  left: 20%;
  bottom: -70%;
  width: 18px;
  height: 18px;
}

.branch5 .branch-inner1 .leaf2 {
  width: 16px;
  height: 16px;
  transform: rotate(-120deg);
  top: 85%;
  left: 40%;
}

.branch5 .branch-inner1 .leaf3 {
  width: 14px;
  height: 14px;
  transform: rotate(-50deg);
  top: 65%;
  left: 80%;
}

.branch5 .branch-inner2 .leaf1 {
  transform: rotate(80deg);
  left: 70%;
  top: 110%;
  width: 18px;
  height: 18px;
  background: #c5eabe;
}

.branch5 .branch-inner2 .leaf2 {
  width: 16px;
  height: 16px;
  transform: rotate(-140deg);
  left: 43%;
  top: 85%;
  background: #c5eabe;
}

.branch5 .branch-inner2 .leaf3 {
  width: 14px;
  height: 14px;
  transform: rotate(100deg);
  left: 20%;
  top: 75%;
  background: #c5eabe;
}

.branch5 .flower1 {
  width: 18px;
  height: 18px;
  left: -10px;
  top: -8px;
}

.branch5 .flower2 {
  transform: rotate(40deg);
  width: 30px;
  height: 30px;
  left: -15px;
  top: -10px;
}

.branch5 .heart {
  width: 18px;
  height: 18px;
  top: -8px;
  left: -10px;
  transform: rotate(-10deg);
}

.branch5 .branch-inner1 .heart {
  width: 18px;
  height: 18px;

  top: -20%;
  left: 80%;
  transform: rotate(35deg);
}

.branch5 .branch-inner2 .tulip {
  width: 20px;
  height: 20px;

  top: 5px;
  left: -5px;
  transform: rotate(-15deg);
}

.branch5>.branch-inner1>.flower1 {
  transform: rotate(10deg);
  width: 20px;
  height: 20px;
  right: 5px;
  bottom: 25px;
}

.branch5 .branch-inner1 .flower1 {
  transform: rotate(10deg);
  width: 18px;
  height: 18px;
  left: 25px;
  top: -15px;
}

.branch5 .branch-inner1 .flower2 {
  transform: rotate(-10deg);
  width: 30px;
  height: 30px;
  left: 25px;
  top: -15px;
}

.branch5 .branch-inner1 .heart {
  width: 18px;
  height: 18px;
  left: 25px;
  top: -15px;
  transform: rotate(35deg);
}

.branch5 .branch-inner2 .heart {
  width: 28px;
  height: 18px;
  left: -5px;
  top: 0px;
  transform: rotate(35deg);
}

.branch5 .branch-inner2 .flower1 {
  transform: rotate(10deg);
  width: 18px;
  height: 18px;
  left: -5px;
  top: 0px;
}

.branch5 .branch-inner2 .flower2 {
  transform: rotate(-5deg);
  width: 30px;
  height: 30px;
  left: -12px;
  top: -2px;
}
.tree-flex{
  display:flex;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 10%;
}

.tree-stats{
  height: 6rem;
}
/* Responsive styles */
@media (max-width: 768px) {
  .tree-flex  {
    display: grid;
    
  }
  .tree-stats{
    height: 0rem;
  }
  

}

      .tree-container {
        display: block;
        max-width: 1200px;
        margin: 0 auto;
        padding: 10px;
      }

      .tree-visualization {
        margin-bottom: 5px;
        text-align: center;
      }

      .tree-title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: left;
      }

      .tree-display {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 300px;
      }

      .session-data {
        display: block;
        margin-top: 10px;
        border-top: 1px solid #eaeaea;
        padding-top: 10px;
      }

      .session-metadata {
        margin-bottom: 15px;
        padding: 8px;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        background-color: #f9f9f9;
        text-align: left;
      }

      .section-title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 15px;
        color: #333;
      }

      .info-content {
        font-size: 0.95rem;
        line-height: 1.25;
      }

      .info-content p {
        margin-bottom: 10px;
        margin-top: 8px;        
      }

      .stats-section {
        margin-top: 10px;
      }

      .stats-container {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        padding: 5px;
        background-color: #ffffff;
      }

      .stats-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 5px;
        text-align: left;
      }

      .stats-table th {
        background-color: rgb(75, 85, 99);
        color: white;
        padding: 10px;
        text-align: left;
        font-weight: bold;
      }

      .stats-table th:last-child {
        text-align: right;
      }

      .stats-table td {
        padding: 10px;
        border-bottom: 1px solid #eee;
      }

      .stats-table td:last-child {
        text-align: right;
      }

      .flower-icon {
        display: inline-block;
        transform: scale(0.5);
        transform-origin: left center;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .total-row {
        font-weight: bold;
      }

      .no-data-message {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        color: #666;
        font-size: 1.1rem;
        background-color: #f5f5f5;
        border-radius: 8px;
      }

      .loading-container {
        text-align: center;
        padding: 30px;
        color: #666;
      }
    .no-data-message{
      height: 390px;
    }

.tree-fullness-info{
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px auto;
  text-align: center;  
}
   